import React, { Fragment } from "react";

import Styles from "./web_development.module.css";

import { Helmet } from "react-helmet";
import Industries_We_Work from "../../components/Industries We Work With/IndustriesWeWork.js";
// image imports
import web_design_service from "../../img/website development service.svg";
import factorsWebDev from "../../img/factors_webd_evelopment.png";

import contentManagementSystem from "../../img/content managmeny system icon.svg";
import socialMedia from "../../img/social media integration icon.svg";
import webApplication from "../../img/web application icon.svg";
import mobileApplication from "../../img/application icon.svg";
import mobileOptimizedApp from "../../img/mobile optimized system icon.svg";
import docManagmentSystem from "../../img/document management system icon.svg";

const WebDevlopment = () => {
  return (
    <Fragment>
      <Helmet>
        <title> Web Development Company in Gurgaon | Skywalk Technologies</title>
        <link rel="canonical" href="https://www.skywalktech.in/web-development"/>
        <meta
          name="description"
          content="Skywalk Technologies is the most trusted web development company in Gurgaon, India, We have a professional team of website development"
        />
        <meta property="og:title" content=" Web Development Company in Gurgaon | Skywalk Technologies" />
        <meta property="og:description" content="Skywalk Technologies is the most trusted web development company in Gurgaon, India, We have a professional team of website development" />
         <meta
          name="keywords"
          content=" web development agency in gurgaon,web development company,top web development company gurgaon, website development company in gurgaon,"
        />
      </Helmet>

      {/*top page header starts here */}
      <section className={Styles.page_header_container}>
        <div className={Styles.page_header}>
          <div className={Styles.main_heading}>
            <h1>Top Website Development Company</h1>
            <p className={Styles.lead}>
                Skywalk technologies is a top web and mobile design and development
              company that offers scalable web development solutions across the
              verticals. SkyWalk is the top web development company offering
              expandable website development services across verticals
            </p>
          </div>
        </div>
      </section>

      {/* feature section  */}
      <section className={Styles.web_feature_section}>
        <div className={Styles.web_feature_wrapper}>
          <div className={Styles.web_feature_heading}>
            <h2>Best Web Development Services</h2>
          </div>
          <div className={Styles.web_feature_content}>
            <div className={Styles.left}>
              <img
                src={web_design_service}
                alt="Best Web Development Services in Gurgaon"
              />
            </div>
            <div className={Styles.right}>
              <p>
                  Skywalk technologies is an established web development company
                that provides far-reaching web development services with
                customer pivotal access. We have a team of highly purposeful web
                developers offering adaptable and vital web services globally.
                Our web developers are skillful in providing custom, built and
                highly expandable websites. Our team of web developers has more
                than 5+ years of experience and expertise in straight-up
                domains. They are accomplished with the latest technologies and
                tools to deliver absolute website development services. Our web
                development team is formative and forward-looking when you think
                of present-day technologies and trends. We provide complete web
                development results from design, development, testing to launch.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* industries we work with */}
      <Industries_We_Work />

      {/*Second feature section starts */}
      <section className={Styles.our_features}>
        <div className={Styles.our_feature_wrapper}>
          <div className={Styles.our_feature_heading}>
            <h3>Web Development Service Provider</h3>
            <p>
              We specialize in developing reliable and creative web development
              solutions that are completely scalable, catering to both simple
              and complex website requirements. With our minimizing cost, we
              provide a range of online solutions. Our website development
              process include:
            </p>
            <ul>
              <li>
                <span>Technical Assessment:</span> Here we formulate the
                situation, goals, and requirements as per the idea.
              </li>
              <li>
                <span>Planning: </span>Creating a tech strategy is crucial, as
                we work on it.
              </li>
              <li>
                <span>Information Architecture + Design + Content:</span>We make
                sure everything falls into its place.
              </li>

              <li>
                <span>Coding:</span> Get ready to get impeccable front and
                back-end coding.
              </li>
              <li>
                <span>QA:</span>Testing is vital and so does our teams run tests
                over 100 checkpoints to be assured.
              </li>
              <li>
                <span>CMS Training + Launch: </span>You learn how to operate and
                we hand over the keys with love.
              </li>
            </ul>
          </div>
          <div className={Styles.our_feature_cards}>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img
                  src={contentManagementSystem}
                  alt="the best organic content managment in Gurgaon"
                />
              </div>
              <h3>Content Managmeny System</h3>
              <p>
                CMS is a software application that is used to build and maintain
                digital content.
              </p>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img
                  src={socialMedia}
                  alt="best social media marketing integration In India"
                />
              </div>
              <h3>Social Media Integration</h3>
              <p>
                Allows users to quickly operate to their favorite network as a
                means to search out.
              </p>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img loading="lazy" src={webApplication} alt="best web application in India" />
              </div>
              <h3>Web Application</h3>
              <p>An application program that is reserved on a remote server.</p>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img
                  src={mobileApplication}
                  alt="one of the best mobile application near me"
                />
              </div>
              <h3>Mobile Application</h3>
              <p>A software application designed to run on a mobile device.</p>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img
                  src={mobileOptimizedApp}
                  alt="best digital mobile-optimize website services in Gurgaon"
                />
              </div>
              <h3>Mobile Optimized Website</h3>
              <p>
                The site is redesigned itself for a list of manually or tablet
                devices.
              </p>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img
                  src={docManagmentSystem}
                  alt="best document management system "
                />
              </div>
              <h3>Document Management System</h3>
              <p>
                A system used to track, maintain, store documents and reduce
                paper.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Third feature section starts froom here */}
      {/* feature section  */}
      <section className={Styles.web_feature_section}>
        <div className={Styles.web_feature_wrapper}>
          <div className={Styles.web_feature_heading}>
            <h2>Why Go For Web Development?</h2>
          </div>
          <div className={Styles.web_feature_content}>
            <div className={Styles.left}>
              <img
                src={factorsWebDev}
                alt="the best ecommerce website design in Gurgaon"
              />
            </div>
            <div className={Styles.right}>
              <p>
                In the world of technological transformation, the significance
                of the digital world needs no confession. We all know that a
                website is of great importance to the businesses and it can
                rightly drive surged conversions rates and better sales.
                <br />
                In short, an efficiently curated website can help your business
                to raise product awareness, manage communication between you and
                possible clients, help in selling the products and services,
                generate leads for the company, and more importantly boost the
                reputation of your company. The factors of Web development
                include: Cost-effective Increasing credibility Enhanced
                marketing Better customer service Easy Maintenance
                Cross-Platform Capabilities With our passionate team of web
                developers, we promise to deliver you exceptional web
                development services. web development agency gurgaon.
              </p>

              <strong
                style={{ color: "#071c4d" }}
                className={Styles.our_services}
              >
                We cater to services like:
              </strong>
              <ul>
                <li>Custom Web Design</li>
                <li>UX/UI Design</li>
                <li>Mobile App Design</li>
                <li>e-Commerce Website Design</li>
                <li>Wordpress Website Design</li>
                <li>Website Speed Optimization</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default WebDevlopment;
