import React, { Fragment } from "react";
import Styles from "./flutter_app.module.css";
import { Helmet } from "react-helmet";
import whyFlutter from "../../img/why-flutter-app-developement.png"
import flutterDev from "../../img/Flutter app development service  _vector_img.svg";
import androidApp from "../../img/Android App Development _ICON.svg";
import blockchain from "../../img/Blockchain App Development _ICON.svg";
import iosApp from "../../img/iOS App Development_ICON.svg";
import multilingual from "../../img/Multilingual App Development_ICON.svg";
import react from "../../img/React Native App Development _ICON.svg";
// component import
import IndustriesWeWork from "../../components/Industries We Work With/IndustriesWeWork";

const FlutterAppDevelopment = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Flutter App Development Services Company | Skywalk Technologies</title>
        <link rel="canonical" href="https://www.skywalktech.in/flutter-app-development"/>

        <meta
          name="description"
          content="Skywalk Technologies offer flutter app development services to build scalable and highly functional mobile applications Call us today."
        />
        <meta
          name="keywords"
          content="flutter developer,flutter app development company,flutter app developer,flutter application development services, flutter app development agency,flutter app development agency in Gurgaon,flutter app development company in Gurgaon,flutter app developer in India ,flutter app development agency in India,flutter app development agency near me ,flutter application development services near me "
        />
        <meta property="og:title" content="Flutter App Development Services Company | Skywalk Technologies" />
<meta property="og:description" content="Skywalk Technologies offer flutter app development services to build scalable and highly functional mobile applications Call us today." />
      </Helmet>
      {/*top page header starts here */}
      <section className={Styles.page_header_container}>
        <div className={Styles.page_header}>
          <div className={Styles.main_heading}>
            <h1>Best Flutter App Development Company</h1>

            <p className={Styles.lead}>
                Skywalk technologies is a top Flutter app development company that
              brings the flexibility of Flutter to your mobile apps. Our Flutter
              app developers are the most prized asset for us.
            </p>
          </div>
        </div>
      </section>

      {/* feature section  */}
      <section className={Styles.android_feature_section}>
        <div className={Styles.android_feature_wrapper}>
          <div className={Styles.android_feature_content}>
            <div className={Styles.left}>
              <img
                src={flutterDev}
                alt="best flutter development company in Gurgaon"
              />
            </div>
            <div className={Styles.right}>
              <h2>Hire Flutter App Developers</h2>
              <p>
                As a renowned flutter app development company, we majorly focus
                on the quality of our product and at   Skywalk technologies, we have
                well-experienced flutter app developers who can develop the
                next-generation applications for the clients in record time. If
                you are focused on Flutter, it's time to hire Flutter app
                developers from   Skywalk technologies who have vast experience in
                imparting world-class flutter app development services. We are
                renowned for offering the best and latest technology solutions
                to our clients. If you have an idea then we will suggest the
                best strategy. We will help you to develop efficient flutter
                applications for both Android and iOS that meet the specific
                business development project.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* second feature section */}
      <section className={Styles.mobile_service_section}>
        <div className={Styles.mobile_services_wrapper}>
          <div className={Styles.mobile_heading}>
            <h2>Mobile App Development Services</h2>
            <p>
              Our Android application development strategy is built around a
              mixture of approaches that allow a business to cover all your
              requirements while optimizing the time and cost of delivering an
              app. Here are the two key approaches our Android app developers
              deploy during the app development process.
            </p>
          </div>

          <div className={Styles.flutter_container}>
            <div
              className={`${Styles.flutter_container_inner} ${Styles.flutter_container_inner_left}`}
            >
              <div className={Styles.flutter_container_item}>
                <div className={Styles.flutter_container_img}>
                  <img
                    src={androidApp}
                    alt="mobile app development company in India"
                  />
                  <h5 className={Styles.flutter_container_item_heading}>
                    Android App Development
                  </h5>
                </div>
              </div>
              <div className={Styles.flutter_container_item}>
                <div className={Styles.flutter_container_img}>
                  <img loading="lazy" src={iosApp} alt="iOS App Development" />
                  <h5 className={Styles.flutter_container_item_heading}>
                    iOS App Development
                  </h5>
                </div>
              </div>
            </div>
            <div
              className={`${Styles.flutter_container_inner} ${Styles.flutter_container_inner_right}`}
            >
              <div className={Styles.flutter_container_item}>
                <div className={Styles.flutter_container_img}>
                  <img loading="lazy" src={blockchain} alt="Blockchain App Development" />
                  <h5 className={Styles.flutter_container_item_heading}>
                    Blockchain App Development
                  </h5>
                </div>
              </div>
              <div className={Styles.flutter_container_item}>
                <div className={Styles.flutter_container_img}>
                  <img loading="lazy" src={react} alt="React Native App Development" />
                  <h5 className={Styles.flutter_container_item_heading}>
                    React Native App Development
                  </h5>
                </div>
              </div>
              <div className={Styles.flutter_container_item}>
                <div className={Styles.flutter_container_img}>
                  <img loading="lazy" src={multilingual} alt="Multilingual App Development" />
                  <h5 className={Styles.flutter_container_item_heading}>
                    Multilingual App Development
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Industries we work with */}
      <IndustriesWeWork />

      {/* feature section  */}
      <section className={Styles.android_feature_section}>
        <div className={Styles.android_feature_wrapper}>
          <div className={Styles.android_feature_content}>
            <div className={Styles.left}>
              <img
                src={whyFlutter}
                alt="skywalk provides the best flutter development services in Gurgaon"
              />
            </div>
            <div className={Styles.right}>
              <h2 style={{ marginLeft: "-18px" }}>
                Flutter Development Process
              </h2>
              <ul>
                <li>
                  <strong>Consider: </strong>We outline your company's
                  objectives and expectations after conducting a thorough
                  business study, and we also offer potential solutions.
                </li>
                <li>
                  <strong>Plan: </strong>With the advantages, you receive a
                  thorough specification, estimate, or module breakdow
                </li>
                <li>
                  <strong>Design : </strong>Using the study, you are presented
                  with the ideal architecture and UX/UI design for your
                  approval.
                </li>
                <li>
                  <strong>Release Testing: </strong>To make sure your app
                  functions flawlessly, we keep track of all reports.
                </li>
                <li>
                  <strong>Develop:</strong> Our Flutter app developer
                  concurrently constructs a single code base for the two apps.
                </li>
                <li>
                  <strong>Check and Integrate: </strong>All of the components
                  are thoroughly tested to the specifications by our Flutter app
                  development business.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default FlutterAppDevelopment;
