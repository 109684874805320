import React,{Fragment} from "react";
import Styles from "./industries_we_work.module.css"
// image imports
import web_design from "../../img/webdevelopmentcompany.png"
import realEstate from "../../img/real-estate.png"
import tourTravels from "../../img/tour-travels.png";
import education from "../../img/education.png";
import transport from "../../img/transport.png";
import events from "../../img/events.png";
import eCommerce from "../../img/ecommmerce.png";
import game from "../../img/game.png";
import healthcare from "../../img/healthcare.png";
import finance from "../../img/finance.png";
import restaurant from "../../img/restaurant.png";
import onDemand from "../../img/on-demand.png";
import grocery from "../../img/grocery.png";
import jewelry from "../../img/jewelry.png";
import automobile from "../../img/automobile.png";
import fitnessCenter from "../../img/fitness-center.png";
import blob from "../../img/shape/blob.png"


const Industries_We_Work = ()=>{
    return (
        <Fragment>
             

          <section style={{ backgroundImage: `url(${blob})` }} className={Styles.industries_work_section}>
                <div className={Styles.industry_work_wrapper}>
                    <div className={Styles.industry_heading}>
                        <h3>Industries We work with - Skywalk Technologies
</h3>
                        <p>Regardless of whether you need an app for banking & finance, healthcare, online food delivery, or gaming domain, we always keep up with you by delivering the best mobile application services and by adjoining your dreams with reality.</p>
                    </div>
                    <div className={Styles.deals_with_cont}>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={realEstate} alt="Real Estate Website Design company , Real Estate" />
                            <h6>Real Estate</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={tourTravels} alt="Tour & Travels" />
                            <h6>Tour & Travels</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={transport} alt="Transport" />
                            <h6>Transport</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={events} alt="Events" />
                            <h6>Events</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={eCommerce} alt="Ecommerce" />
                            <h6>Ecommerce</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={game} alt="Game" />
                            <h6>Game</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={healthcare} alt="Health Care" />
                            <h6>Health Care</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={finance} alt="Finance" />
                            <h6>Finance</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={restaurant} alt="Restraunt" />
                            <h6>Restraunt</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={onDemand} alt="On-Demand" />
                            <h6>On-Demand</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={grocery} alt="Grocery" />
                            <h6>Grocery</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={jewelry} alt="Jwelery" />
                            <h6>Jwelery</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={education} alt="Education" />
                            <h6>Education</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={automobile} alt="Automobile" />
                            <h6>Automobile</h6>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="100" className={Styles.field}>
                            <img loading="lazy" src={fitnessCenter} alt="Fitness Center" />
                            <h6>Fitness Center</h6>
                        </div>
                    </div>
                </div>
            </section>

        </Fragment>
    )
}

export default Industries_We_Work;