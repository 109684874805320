import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

import { FaFacebookF, FaQuora } from "react-icons/fa";
import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import {AiOutlineTwitter} from "react-icons/ai";

import Styles from "./footer.module.css";
import skywalktech from "../../img/skywalk_footer_logo.png";

function Footer() {
  return (
    <Fragment>
      <div className={Styles.footer_wrapper}>
        <div className={Styles.content_container}>
          <div className={`${Styles.box1}  ${Styles.box} `}>
            <img loading="lazy" src={skywalktech} alt="skywalk logo" />
            <p>
              In this digital era, when everything has turned digitally,
              marketing has not remained untouched. Being digitally sound means
              you are all set to achieve your goal 3X faster than those who
              still follow the traditional method of marketing strategy and
              implement the old sales technique for business growth and brand
              marketing.
            </p>
            <a href="mailto:info@skywalktech.in" style={{color:'white'}}>Email: info@skywalktech.in</a>
          </div>
          <div className={Styles.links_cont}>
            <div
              className={` ${Styles.box2}  ${Styles.box}  ${Styles.pages_wrapper}`}
            >
              <h3>Primary Pages</h3>
              <ul>
                <NavLink className={Styles.link} to="/">
                  <li>Home</li>
                </NavLink>
                <NavLink className={Styles.link} to="/company-profile">
                  <li>About Us</li>
                </NavLink>
                
                <NavLink className={Styles.link} to="/career">
                  <li>Career</li>
                </NavLink>
                <NavLink className={Styles.link} to="/casestudy">
                  <li>Case study</li>
                </NavLink>
                <NavLink className={Styles.link} to="/portfolio">
                  <li>Portfolio</li>
                </NavLink>
                <NavLink className={Styles.link} to="/contact-us">
                  <li>Contact Us</li>
                </NavLink>
              </ul>
            </div>
            <div
              className={`${Styles.box3} ${Styles.box} ${Styles.others_wrapper}`}
            >
              <h3>Others</h3>
              <NavLink className={Styles.link} to="/portfolio">
                <p>Our Work</p>
              </NavLink>
              <NavLink className={Styles.link} to="/digital-marketing">
                <p>Digital Marketing Company</p>
              </NavLink>
           
              <NavLink className={Styles.link} to="/mobile-game-app-development">
                <p>Mobile Game development</p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className={Styles.copyright}>
          <p>© 2021-2022 Skywalk Technologies Pvt. Ltd.</p>
          <div className={Styles.socials}>
            <a
              rel="noreferrer"
              href="https://www.facebook.com/skywalktechnologiesofficial"
              target="_blank"
              className={Styles.link}
            >
              <span>
                <FaFacebookF className={Styles.icon} />
              </span>
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/skywalktechofficial/"
              target="_blank"
              className={Styles.link}
            >
              <span>
                <AiOutlineInstagram className={Styles.icon} />
              </span>
            </a>
            <a
              rel="noreferrer"
              href="https://twitter.com/skywalktech1"
              target="_blank"
              className={Styles.link}
            >
              <span>
                <AiOutlineTwitter className={Styles.icon} />
              </span>
            </a>

            <a
              rel="noreferrer"
              href="https://www.quora.com/profile/SkyWalk-Technologies"
              target="_blank"
              className={Styles.link}
            >
              <span>
                <FaQuora className={Styles.icon} />
              </span>
            </a>

            <a
              rel="noreferrer"
              href="https://www.linkedin.com/company/skywalk-technologies-pvt-ltd/"
              target="_blank"
              className={Styles.link}
            >
              <span>
                <AiFillLinkedin className={Styles.icon} />
              </span>
            </a>

            <a
              rel="noreferrer"
              href="https://www.youtube.com/@Skywalktechnologies"
              target="_blank"
              className={Styles.link}
            >
              <span>
                <AiFillYoutube className={Styles.icon} />
              </span>
            </a>







            {/* 
                   
                    <NavLink to="/under-maintainence" className={Styles.link}> <span><SiTelegram className={Styles.icon} /></span></NavLink>
                    <NavLink to="/under-maintainence" className={Styles.link}> <span><IoLogoWhatsapp className={Styles.icon} /></span></NavLink> 
          */}
         
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
