import { Fragment, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
// import Services from "../Services/Services.js";
import Awards from "../Awards/Awards.js";
// import About from "../About Us/About.js"
import Marketing from "../Marketing/Marketing.js";
import DigitalMarket from "../Digital Marketing/DigitalMarket.js";
import WhySkyWalk from "../Why Skywalk/WhySkyWalk.js";
import PaymentOptions from "../Payment Options/PaymentOptions.js";
import ServiceTeam from "../Service Team/ServiceTeam.js";

import LazySpinner from "../lazy Spinner/LazySpinner.js";

const Banner = lazy(() => import("../Banner/Banner.js"));
const About = lazy(() => import("../About Us/About.js"));
const Services = lazy(() => import("../Services/Services.js"));

const Homepage = () => {
  return (
    <Fragment>
      {/* Meta tags for Seo */}
      <Helmet>
        <title>Skywalk Technologies Pvt. Ltd. | Letspe </title>

        <link rel="canonical" href="https://www.skywalktech.in" />

        {/* <meta
          name="description"
          content="The best digital marketing company in Gurgaon, Delhi, India. We provide best SEO,SMO,PPC services in Gurgaon to increase website organic traffic."
        /> */}
        {/* <meta
          name="keywords"
          content="Best Digital Marketing Agency in gurgaon, Best Digital Marketing Agency in gurgaon, Best Digital Marketing Agency in india, Digital Marketing Company, Digital Marketing Agency, Online Advertising, Online Promotions, Digital Marketing Services ,Brand promotion,Digital team in gurgaon,Want to grow business,best marketing company in gurgaon,digital marketing company in spaze itech,digital marketing company in sector 49 gurgaon,brand marketing company in gurgaon"
        /> */}
      </Helmet>

      <Suspense fallback={<LazySpinner />}>
        <Banner />
      </Suspense>

      <Suspense fallback={<LazySpinner />}>
        <Services />
      </Suspense>

      <Awards />
      <Suspense fallback={<LazySpinner />}>
        <About />
      </Suspense>
      <Marketing />
      <DigitalMarket />
      <WhySkyWalk />
      <PaymentOptions />
      <ServiceTeam />
    </Fragment>
  );
};
export default Homepage;
