import React, { Fragment, useState } from "react";
import Styles from "./service_team.module.css";

// image imports
import num1 from "../../img/num1.png";
import num2 from "../../img/num2.png";
import num3 from "../../img/num3.png";
import digitalMarketing from "../../img/digital_marketing_agency.webp";
import faqIcon from "../../img/faq_icon.png";


const ServiceTeam = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const accordionItems = [
    {
      index: 0,
      title: "What is Digital Marketing?",
      content:
        "We understand Marketing, a method to promote the good & services of the business or company. Digital marketing is the Digital form of promotion of the products and services. \n This uses the Internet (Email, Social Media, Text, Multimedia) & online digital technologies like Computers(Desktop), Mobile Phone & others to market the businesses’ products & services. This is also Known as Online Marketing, is simply, the promotion of products & brands to connect with potential clientele using the online platforms. ",
    },
    {
      index: 1,
      title: "Why is Digital Marketing Important?",
      content:
        (
          <div>
            <p>Digital Marketing is a must for all businesses today as it:</p>
            <ul>
              <li>Allows the business to reach a wider range of clientele</li>
              <li>Top idea to reach out faster to the potential clients digitally.</li>
              <li>Improves Search Rankings.</li>
              <li>Offers measurable analytics of the sales and distribution. </li>
              <li>It is cost-effective in comparison with the traditional methods.</li>
              <li>You don't need to do paid promotion(If Your SEO is Smart).</li>
              <li>No costly physical banners, pamphlets, Calling bills, field marketing and broadcasting on channels is required.</li>
            </ul>
          </div>),
    },
    {
      index: 2,
      title: "What services does a Digital Marketing Agency provide?",
      content:
        `A Digital Marketing agency should be a multitasker and it must resolve all the problems in the digital promotion of the businesses. 
        The Digital marketing agencies offer services like: Search Engine Optimization (SEO), Pay-Per-Click (PPC) Advertising, Social Media Marketing (SMM), Content Marketing, Email Marketing, Mobile Marketing, Video Production, Public Relations (PR), Marketing Analytics, Website Creation, Mobile App Development and Affiliate Marketing. Skywalk Technologies is the single platform that offers all this.
        `,
    },
    {
      index: 3,
      title: "Would my business benefit from Digital Marketing?",
      content:
        `The opinion is not only one firm’s side, but most of the businesses today believe that Digital Marketing helps their business to grow 10 Times better than the traditional method. 
        Digital marketing will help you to promote to a wider range of audience, it is cost-effective (No Costly Promotional Methods are Required) and Keeps analytics of the sales & distribution. 
        Stop running from this smart way to enroll fresh clients as your long term customers by doing easy promotions online. `,
    },
    {
      index: 4,
      title: "Why should you hire a Digital Marketing Agency?",
      content:
        `You are a businessman and Digital Marketing needs experience as well as in-depth knowledge to get the best results. It can be an investment if you hire any Digital Marketing Agency that provides all the services of online promotion. 
        It will be beneficial for you to hire a digital marketing agency as it helps you to get fresh clients and the best part is that you don’t have to invest a lot of your time in this.`,
    },
    {
      index: 5,
      title: "Who provides the most effective Digital Marketing Services in Gurgaon?",
      content:
        `Skywalk Technologies is the emerging firm which offers Digital Marketing Services most effectively. The reason behind it is other than Digital Marketing Services, it does develop Website, Mobile App Development and Affiliate Marketing Services. 
        It helps your business go digital and allows you to get fresh clients online. They have superb client ratings online who are happy with the services and always try to improve on the drawbacks mentioned by the clients. 
        `,
    },
  ];

  const AccordionItem = ({ item, openIndex, setOpenIndex }) => {
    const isOpen = openIndex === item.index;
  
    const toggleAccordion = () => {
      if (isOpen) {
        setOpenIndex(null); // Close the current item if clicked again
      } else {
        setOpenIndex(item.index); // Open the clicked item
      }
    };

    return (
      <div className={`${Styles.faq_item} ${isOpen ? Styles.open : ""}`}>
        {/* <div className={Styles.icon}>
          <img loading="lazy" src={faqIcon} alt="FAQ Icon" />
        </div> */}
        <div className={Styles.content} onClick={toggleAccordion}>
          <h4 className={isOpen ? Styles.openTitle : ""}><img loading="lazy" src={faqIcon} alt="FAQ Icon" />{item.title}</h4>
          {isOpen && <p className={Styles.openContent}>{item.content}</p>}
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      <div className={Styles.team_wrapper}>
        <div className={Styles.content_cont}>
          <div className={Styles.title}>
            <h4>
              Learn more about Our Digital <br />
              Service Team
            </h4>
          </div>
          <div className={Styles.flex_wrapper}>
            <div className={Styles.left_faq_wrapper}>
              <div className={Styles.faq_item}>
                <div className={Styles.sequence}>
                  <img loading="lazy" src={num1} alt="point 1" />
                </div>
                <div className={Styles.content}>
                  <h4> GREAT TEAMWORK</h4>
                  <p>
                    Teamwork plays an important role in success. We believe in
                    passionate and hard working people, no matter their
                    background or years of experience.
                  </p>
                </div>
              </div>
              <div className={Styles.faq_item}>
                <div className={Styles.sequence}>
                  <img loading="lazy" src={num2} alt="point 2" />
                </div>
                <div className={Styles.content}>
                  <h4>DEDICATED FORCE</h4>
                  <p>
                    Our team works in a collaborative manner at every stage of
                    the project to provide the desired and best results.
                    Customer satisfaction is our priority.
                  </p>
                </div>
              </div>
              <div className={Styles.faq_item}>
                <div className={Styles.sequence}>
                  <img loading="lazy" src={num3} alt="point 3" />
                </div>
                <div className={Styles.content}>
                  <h4> NEVER SAY NO</h4>
                  <p>
                    We follow a Never say "No" rules, in a positive way. This
                    helps us constantly innovate because where others see
                    difficulty we see opportunity and try to change difficulties
                    into innovation.
                  </p>
                </div>
              </div>
            </div>
            <div className={Styles.right_wrapper}>
              <img loading="lazy" src={digitalMarketing} alt="best digital team in Gurgaon" />
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.faq_wrapper}>
        <div className={Styles.faq_container}>
          <div className={Styles.faq_heading}>
            <h4>Frequently Asked Question ?</h4>
          </div>
          <div className={Styles.faq_item_container}>
            <div className={Styles.column}>
              {accordionItems.slice(0, Math.ceil(accordionItems.length / 2)).map((item, index) => (
                <AccordionItem key={index} item={item} openIndex={openIndex}
                setOpenIndex={setOpenIndex}/>
              ))}
            </div>
            <div className={Styles.column}>
              {accordionItems.slice(Math.ceil(accordionItems.length / 2)).map((item, index) => (
                <AccordionItem key={index + Math.ceil(accordionItems.length / 2)} item={item} openIndex={openIndex} setOpenIndex={setOpenIndex} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceTeam;
