import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Styles from "./angular.module.css";
import Industries_We_Work from "../../components/Industries We Work With/IndustriesWeWork.js";
// image imports
import angularjs from "../../img/angular1.png";

//
import factors from "../../img/factors.png";

import { RiNodeTree } from "react-icons/ri";
import { BsBezier, BsTruck } from "react-icons/bs";
import { BiLayer } from "react-icons/bi";
const Angular = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          Angular JS Development Company in Gurgaon | Skywalk Technologies
        </title>
        <link rel="canonical" href="https://www.skywalktech.in/angularjs" />
        <meta
          name="description"
          content="Skywalk Technologies provides the best Angular JS development service in Gurgaon, India at an affordable price. For more details, visit us today."
        />
        <meta
          name="keywords"
          content="angular js developer, angular js developer gurgaon,angular js developer service,angular js service india,angular js developer service in gurgaon,angular js develpoer service in India"
        />
        <meta
          property="og:title"
          content="Angular JS Development Company in Gurgaon | Skywalk Technologies"
        />
        <meta property="og:description"
    content="Skywalk Technologies provides the best Angular JS development service in Gurgaon, India at an affordable price. For more details, visit us today. " />

      </Helmet>

      {/*top page header starts here */}
      <section className={Styles.page_header_container}>
        <div className={Styles.page_header}>
          <div className={Styles.main_heading}>
            <h1>AngularJS Development Company</h1>
            <p className={Styles.lead}>
              Skywalk technologies is the best offshore AngularJS development
              company in India, USA and UAE focused on providing angularJS
              development services for clients across the globe. SkyWalk
              Technology is developing web apps by leveraging the potential of
              Angular and has successfully developed web apps for varied
              industries with complex requirements. To make sure you stay ahead
              in the market, we use the best Angular tools and features for your
              project.
            </p>
          </div>
        </div>
      </section>

      {/* feature section  */}
      <section className={Styles.web_feature_section}>
        <div className={Styles.web_feature_wrapper}>
          <div className={Styles.web_feature_heading}>
            <h2>Features Skywalk Provides You For AngularJS</h2>
            <p>
              Angular has the following key features which makes it one of the
              powerful frameworks in the market:
            </p>
          </div>
          <div className={Styles.web_feature_content}>
            <div className={Styles.left}>
              <img
                loading="lazy"
                src={angularjs}
                alt="skywalk provides agularJS company in Gurgaon"
              />
            </div>
            <div className={Styles.right}>
              <p>
                MVC The framework is based on the well-known MVC idea
                (Model-View-Controller). Today's online apps employ this design
                pattern consistently. This design pattern is built on segmenting
                the display layer, the data layer, and the business logic layer.
                The divide is made into many portions to make it easier to
                manage each one separately.
                <br />
                Data Model Binding –You don’t need to write special code to bind
                data to the HTML controls. This can be done by Angular by just
                adding a few snippets of code.
                <br />
                Writing less code Designing any programme involves writing a lot
                of JavaScript when doing DOM manipulation. But with Angular,
                you'll be astounded by how little code is required for DOM
                manipulation.
                <br />
                Unit Testing ready- The designers at Google not only developed
                Angular but also developed a testing framework called “Karma”
                which helps in designing unit tests for AngularJS applications.
              </p>

              <strong
                style={{ color: "#071c4d" }}
                className={Styles.our_services}
              >
                AngularJS services we deal into:
              </strong>
              <ul>
                <li>AngularJS Customization</li>
                <li>Web Application Development</li>
                <li>Plugin Development</li>
                <li>AngularJS Portal Development</li>
                <li>AngularJS eCommerce Development</li>
                <li>Website Speed Optimization</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* industries we work with */}
      <Industries_We_Work />

      {/* why Angular section starts here*/}
      <section className={Styles.why_angular_section}>
        <div className={Styles.why_angular_container}>
          <div className={Styles.content_wrapper}>
            <div className={Styles.angular_heading}>
              <h3>Process</h3>
              <h2>Why Chose AngularJS For Development Purpose?</h2>
              <p>
                Angular JS is powered by Google and is the preferred choice of
                our developers because of its various benefits. It is easy to
                access, integrates the architecture of MVVM, and MVC models.
                Here are some of the benefits of AngulrJS that you need to know.
              </p>
            </div>
            <div className={Styles.content_section}>
              <div className={Styles.images_section}>
                <img
                  loading="lazy"
                  src={factors}
                  alt=" the best AngularJS For Development services"
                />
              </div>
              <div className={Styles.text_content_cont}>
                <div className={Styles.tree_structure}>
                  <div className={Styles.timeline_icon}>
                    <RiNodeTree className={Styles.ic} />
                  </div>
                  <div className={Styles.line}></div>
                  <div className={Styles.timeline_icon}>
                    <BsBezier className={Styles.ic} />
                  </div>
                  <div className={Styles.line}></div>
                  <div className={Styles.timeline_icon}>
                    <BiLayer className={Styles.ic} />
                  </div>
                  <div className={Styles.line}></div>
                  <div className={Styles.timeline_icon}>
                    <BsTruck className={Styles.ic} />
                  </div>
                </div>
                <div className={Styles.text_cont}>
                  <div>
                    <h2>Declarative User Interface </h2>
                    <p> It has a user-friendly interface defined by HTML.</p>
                  </div>
                  <div>
                    <h2>Unit Testing </h2>
                    <p>
                      Angular JS is linked together by (DI) Dependency
                      Injection.
                    </p>
                  </div>
                  <div>
                    <h2>Powerful Code</h2>
                    <p>
                      Our codes are lightweight, yet they manage to be powerful
                      enough to break the stereotypes of lightweight codes.
                    </p>
                  </div>
                  <div>
                    <h2>Bug-Free Applications</h2>
                    <p>
                      After releasing the app, we make sure to update it and
                      keep a tab on the community built around it.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Angular;
