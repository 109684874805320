import React, { Fragment } from 'react'
import Styles from "./payment_options.module.css"

import driveIcon from "../../img/integations/google-drive.png"
import googleIcon from "../../img/integations/2.png"
import snapIcon from "../../img/integations/3.png"
import diamondIcon from "../../img/integations/4.png"
import figmaIcon from "../../img/integations/5.png"
import spotifyIcon from "../../img/integations/6.png"
import zeplinIcon from "../../img/integations/7.png"
import dribbleBallIcon from "../../img/integations/8.png"
import slackIcon from "../../img/integations/9.png"
import lineIcon from "../../img/integations/10.png"
import googleAnalyticIcon from "../../img/integations/11.png"
import vscodeIcon from "../../img/integations/12.png"
import { NavLink } from 'react-router-dom'

const PaymentOptions = () => {
    return (
        <Fragment>
            <div className={Styles.payment_wrapper}>
                <div className={Styles.inner_payment_section}>
                    <div className={Styles.box1}>
                        <div className={`${Styles.payment_item} ${Styles.left}`}>
                            <img loading="lazy" src={driveIcon} alt="drive" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.right}`}>
                            <img loading="lazy" src={googleIcon} alt="Google" />
                        </div>
                        <div className={`${Styles.payment_item}  ${Styles.left}`}>
                            <img loading="lazy" src={snapIcon} alt="snapchat" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.right}`}>
                            <img loading="lazy" src={diamondIcon} alt="sketch" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.left}`}>
                            <img loading="lazy" src={figmaIcon} alt="figma" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.right}`}>
                            <img loading="lazy" src={spotifyIcon} alt="spotify" />
                        </div>
                    </div>
                    <div className={Styles.box2}>
                        <h3>Payment Gateway that offers Best Success Rates</h3>
                        <h4>100+ Payment Options for Your Customers</h4>
                        <p>Your Transactions are Secure with Us</p>
                        <NavLink to="/contact-us" className={Styles.button}>Start Integrating Payment Gateway Now!</NavLink>
                    </div>
                    <div className={Styles.box3}>
                        <div className={`${Styles.payment_item} ${Styles.left}`}>
                            <img loading="lazy" src={zeplinIcon} alt="zapline" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.right}`}>
                            <img loading="lazy" src={dribbleBallIcon} alt="dribble" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.left}`}>
                            <img loading="lazy" src={slackIcon} alt="slack" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.right}`}>
                            <img loading="lazy" src={lineIcon} alt="line" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.left}`}>
                            <img loading="lazy" src={googleAnalyticIcon} alt="analytics" />
                        </div>
                        <div className={`${Styles.payment_item} ${Styles.right}`}>
                            <img loading="lazy" src={vscodeIcon} alt="visual studio" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PaymentOptions