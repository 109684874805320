import React, { Fragment } from 'react'
import {Helmet } from "react-helmet"
import Portfolio from '../Portfolio/Portfolio'
const CaseStudy = () => {
    const title = "Case Study"
  return (
    <Fragment>
      <Helmet>
        <title>Best Digital Marketing Agency in  Gurgaon | skywalk Technologies</title>
        <link rel="canonical" href="https://www.skywalktech.in/portfolio" />

        <meta
          name="description"
          content="Skywalk is the best SEO company in Gurgaon, India. We provide the best search engine optimization services, to increase your website traffic at an affordable cost. "
        />
        <meta
          name="keywords"
          content="websites,SEO,SMO services company in gurgaon"
        />
        <meta
          property="og:title"
          content="Best Digital Marketing Agency in  Gurgaon | skywalk Technologies"
        />
        <meta
          property="og:description"
          content="Skywalk is the best SEO company in Gurgaon, India. We provide the best search engine optimization services, to increase your website traffic at an affordable cost."
        />
      </Helmet>
       
       <Portfolio pageTitle="Case study Skywalk technologies | Digital Marketing  Agency" title={title}/>
    </Fragment>
  )
}

export default CaseStudy
