import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Styles from "./java_development.module.css";
import Industries_We_Work from "../../components/Industries We Work With/IndustriesWeWork.js";

// image imports

import java1 from "../../img/java1.png";
import javaCircleImg from "../../img/circle.png";
import whyjava from "../../img/why_java.png";

const JavaDevelopment = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
        Java Development Software Company in Gurgaon | Skywalk Technologies
        </title>
        <link
          rel="canonical"
          href="https://www.skywalktech.in/java-development"
        />

        <meta
          name="description"
          content="Skywalk Technologies is one of the best Java development software company in Gurgaon, India. we provide the best Java development solution for your business  "
        />
        <meta
          name="keywords"
          content="java development company,java development company India ,java application development company,java development services,best java development company,web application development company India,software development company,java development company in gurgaon ,best java development company in gurgaon ,java development services in gurgaon ,java development services in India"
        />
        <meta
          property="og:title"
          content=" Java Development Software Company in Gurgaon | Skywalk Technologies"
        />
        <meta
          property="og:description"
          content="Skywalk Technologies is one of the best Java development software company in Gurgaon, India. we provide the best Java development solution for your business "
        />
      </Helmet>

      {/*top page header starts here */}
      <section className={Styles.page_header_container}>
        <div className={Styles.page_header}>
          <div className={Styles.main_heading}>
            <h1>Top Java/J2EE Software Development Company</h1>

            <p className={Styles.lead}>
              Skywalk technologies is the top web and mobile design and
              development company that offers scalable web designing solutions
              across the verticals. Being the most popular programming language
              for Android smartphone apps and most favored for edge device and
              IoT development, we hold complete proficiency in Java.
            </p>
          </div>
        </div>
      </section>

      {/* feature section  */}
      <section className={Styles.java_feature_section}>
        <div className={Styles.java_feature_wrapper}>
          <div className={Styles.web_feature_heading}>
            <h2>
              Why Choose Java Application Development For Your Business Growth?
            </h2>
            <p>
              Here is the reason why the big part of technological evolution
              depends on Java and Java development services.
            </p>
          </div>
          <div className={Styles.java_feature_content}>
            <div className={Styles.left}>
              <img
                loading="lazy"
                src={java1}
                alt="skywalk provides many java development services near me"
              />
            </div>
            <div className={Styles.right}>
              <p>
                Skywalk technologies is a leading company with 5+ experience in
                the app/website development industry. Our team of developers has
                more than 3+ years of industry experience and expertise in
                various domains. Every one of our professionals are well versed
                with the latest technologies and tools to deliver comprehensive
                Java App Development Services, then be it for the website or for
                the applications; we create wonders.
              </p>

              <strong
                style={{ color: "#071c4d" }}
                className={Styles.our_services}
              >
                Why Go With Java:
              </strong>
              <ul>
                <li>Multithreaded</li>
                <li>Secure</li>
                <li>Portable</li>
                <li>Platform-independent</li>
                <li>Interpreted</li>
                <li>Dynamic</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* industries we work with */}
      <Industries_We_Work />

      {/* feature section second */}
      {/* here we have used the same template of java feature section used above */}
      <section className={Styles.java_feature_section}>
        <div className={Styles.java_feature_wrapper}>
          <div className={Styles.java_feature_content}>
            <div className={Styles.left}>
              <img
                loading="lazy"
                src={javaCircleImg}
                alt="we can provide the best java applications software services in Gurgaon"
              />
            </div>
            <div className={Styles.right} style={{ order: "-1" }}>
              <h2 className={Styles.java_heading}>
                Java Application And Software Development Services
              </h2>
              <p>
                IIt’s always wise to customize the appearance of a business
                website and that’s where the role of Java language comes in. Let
                us introduce to you the Java-based services that we provide.
              </p>

              <strong
                style={{ color: "#071c4d" }}
                className={Styles.our_services}
              >
                What’s All We Include in Java:
              </strong>
              <ul>
                <li>Custom Java Development</li>
                <li>Java Web Development</li>
                <li>Java Application & Maintenance</li>
                <li>Java Mobile Development</li>
                <li>Java/J2EE Development</li>
                <li>Java Software Development</li>
                <li>Plugin Development</li>
                <li>Java Portal Development</li>
                <li>Java eCommerce Development</li>
                <li>Java UX/UI Development</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* feature section Third */}
      {/* here we have used the same template just contents are changed */}
      <section className={Styles.java_feature_section}>
        <div className={Styles.java_feature_wrapper}>
          <div className={Styles.java_feature_content}>
            <div className={Styles.left}>
              <img
                loading="lazy"
                src={whyjava}
                alt="The best java services near me"
              />
            </div>
            <div className={Styles.right}>
              <h2 className={Styles.java_heading}>
                Reasons To Choose Us For Java Development Services
              </h2>
              <p style={{ fontWeight: "bolder" }}>
                We are a top-rated Java software development company
              </p>

              <p>
                Java is a high-level language with platform-independence and
                that makes it easy to access and developers' first choice. Java
                is the Preferred language for designing complex applications. It
                is a secure language and has all the benefits that a developer
                can wish for developing an application.
              </p>

              <strong
                style={{ color: "#071c4d" }}
                className={Styles.our_services}
              >
                What’s All We Include in Java:
              </strong>
              <ul>
                <li>Assures a High-Level Security</li>
                <li>Debugging Capability</li>
                <li>Debugging Capability</li>
                <li>Provides Multiple APIs and Libraries</li>
                <li>User-Friendly</li>
                <li>Faster and Efficient</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default JavaDevelopment;
