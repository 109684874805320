import React, { Fragment } from "react";

import mobile from "../../img/Digital-marketing-service.webp";
import { RiNodeTree } from "react-icons/ri";
import { NavLink,Link } from "react-router-dom";
import Styles from "./digital_market.module.css";

const DigitalMarket = () => {
  return (
    <Fragment>
      <div className={Styles.market_container}>
        <div className={Styles.market_content_wrapper}>
          <div className={Styles.heading}>
            <h3>How can our digital marketing service help your brand</h3>
          </div>
          <div className={Styles.content_cont}>
            <div className={Styles.left_cont}>
              <img
                loading="lazy"
                src={mobile}
                alt="our team's digital marketing services help  your brand"
              />
            </div>
            <div className={Styles.right_cont}>
              <div className={Styles.tree_icon_cont}>
                <span>
                  <RiNodeTree className={Styles.icon} />
                </span>
              </div>
              <div className={Styles.marketing_content}>
                <h3>Explore our Digital Marketing services.</h3>
                <p>
                  Technology has assimilated into daily life to the point where
                  everyone must go digital in order to grow their businesses.
                  For this advancement, we at Skywalk Technologies offer you a
                  one-stop shop with all you need. We handle every aspect of
                  your online business with the help of the services .
                  As a digital marketing company, we have this proficiency to work on specific keywords, the understanding of popular social media platforms, and other strategies like Pay per click to enhance the exposure of your website above all of your competitors online.
                </p>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DigitalMarket;
