import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Styles from "./php_dev.module.css";
import Industries_We_Work from "../../components/Industries We Work With/IndustriesWeWork.js";

// image imports
import phpImg from "../../img/PHP development service _vector_img.png";
import phpImg2 from "../../img/PHP development service in gurgaon _vector_img.png";
import php_Dev_Service_Img from "../../img/php_development_service.png";

const PhpDevelopment = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Best PHP Development Company in Gurgaon | Skywalk Technologies </title>
        <link rel="canonical" href="https://www.skywalktech.in/php-development"/>


        <meta
          name="description"
          content="Skywalk Technology is the leading PHP Development Company in Gurgaon, India. we provide custom PHP development services for all your requirements."
        />
        <meta
          name="keywords"
          content="PHP Development Company in India,PHP Develpoment company gurgaon,php development service in gurgaon,php-development in india,
          PHP-based in gurgaon ,PHP developers near me,PHP development in india,PHP developers in gurgaon"
        />
        <meta property="og:title" content="Best PHP Development Company in Gurgaon | Skywalk Technologies " />
<meta property="og:description" content="Skywalk Technology is the leading PHP Development Company in Gurgaon, India. we provide custom PHP development services for all your requirements." />
      </Helmet>
      {/*top page header starts here */}
      <section className={Styles.page_header_container}>
        <div className={Styles.page_header}>
          <div className={Styles.main_heading}>
            <h1>PHP Web Development Company</h1>

            <p className={Styles.lead}>
                Skywalk technologies is the top web and app design and development
              company that offers scalable web designing solutions across the
              verticals. We fabricate tailor-made PHP solutions that are
              responsive, rapid, and adaptable. The maturity of PHP simply
              propel our developers to build engaging, and interactive websites.
            </p>
          </div>
        </div>
      </section>

      {/* feature section  */}
      <section className={Styles.php_feature_section}>
        <div className={Styles.php_feature_wrapper}>
          <div className={Styles.php_feature_content}>
            <div className={Styles.left}>
              <img loading="lazy" src={phpImg} alt="best PHP services near me " />
            </div>
            <div className={Styles.right}>
              <h2>Why you should PHP for your website</h2>

              <p>
                  Skywalk technologies is a trusted PHP web development service
                provider that provides dynamic web pages and web application
                services with quality assurance. We have a team of highly
                devoted PHP developers offering innovative and efficient PHP web
                development services across the globe. Our PHP developers are
                skillful enough to provide custom-built and highly expandable
                websites. <br />
                Our web development team has more than 5+years of experience and
                holds expertise in straight-up domains. The development team is
                well-versed with the latest technologies and tools to deliver
                far-reaching PHP web development services.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* industries we work with */}

      <Industries_We_Work />

      {/* feature section second */}
      {/* here we have used the same template of java feature section used above */}
      <section className={Styles.php_feature_section}>
        <div className={Styles.php_feature_wrapper}>
          <div className={Styles.php_feature_content}>
            <div className={Styles.left}>
              <img
                src={phpImg2}
                alt="we can provide many PHP services in Gurgaon "
              />
            </div>
            <div className={Styles.right} style={{ order: "-1" }}>
              <h2 className={Styles.php_heading}>PHP Development Services</h2>
              <p>
                Our aim is to deliver best-class custom PHP development
                solutions with accuracy. And it’s possible with the support of
                backend and frontend experts, who relish creating dynamic and
                fully-functional websites.
              </p>

              <strong
                style={{ color: "#071c4d" }}
                className={Styles.our_services}
              >
                Here are the PHP-based services that SkyWalk is adept at:
              </strong>
              <ul>
                <li>PHP customization</li>
                <li>Web Application Development</li>
                <li>Plugin Development</li>
                <li>PHP Portal Development</li>
                <li>PHP eCommerce Development</li>
                <li>PHP UX/UI Development</li>
                <li>PHP Based CMS Development</li>
                <li>Corporate Website Development</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* feature section Third */}
      {/* here we have used the same template just contents are changed */}
      <section className={Styles.php_feature_section}>
        <div className={Styles.php_feature_wrapper}>
          <div className={Styles.php_feature_content}>
            <div className={Styles.left}>
              <img
                src={php_Dev_Service_Img}
                alt="skywalk provides best PHP web development services near me"
              />
            </div>
            <div className={Styles.right}>
              <h2 className={Styles.php_heading}>
                Why Choose SkyWalk for PHP Web Development?
              </h2>
              <p>
                We have a sharp and skilled team of developers who have
                successfully worked on PHP web development services. Here are
                some of the factors that set us apart:
              </p>

              <ul>
                <li>
                  
                  <strong>Well Acquainted:</strong> In PHP web development,
                  there are numerous different aspects including the development
                  of applications, e-commerce, and other websites in which we
                  are expert at.
                </li>

                <li>
                  
                  <strong>Rational Experience:</strong> Before hiring the PHP
                  web development services, we assure you that our firm holds
                  considerable experience in the domain of developing and
                  designing websites.
                </li>

                <li>
                  
                  <strong>Specialization:</strong> Our PHP web developers are
                  adept in the app development.
                </li>

                <li>
                  
                  <strong>Accessibility: </strong> For seamless accessibility,
                  are always up with options like telephone, email and live
                  chat.
                </li>

                <li>
                  
                  <strong>Time Frame and Cost:</strong> We promise to get your
                  website ready and delivered in the desired time frame and that
                  too at a much reasonable cost.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PhpDevelopment;
