import React, { Fragment } from "react";
import Styles from "./certifications.module.css";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import skywalk from "../../img/skywalk.jpeg";
import skywalkoffice from "../../img/skywalktech.jpeg";
import skywalkoffice3 from "../../img/skywalkoffice.jpeg";

// office images
import officeImage1 from "../../img/office-img-1.jpg";
import officeImage2 from "../../img/office-img-2.jpg";
import officeImage3 from "../../img/office-img-3.jpg";
import officeImage5 from "../../img/office-img-5.jpg";

const Certifications = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
        Most Trusted & Certified Brand For Digital Business Growth 
        </title>
        <link rel="canonical" href="https://www.skywalktech.in/certifications"/> 

        <meta
          name="description"
          content="Being considered as one of the most trusted brands for digital Business Growth, More than 500 satisfied customers we served all across the globe"
        />
        <meta
          name="keywords"
          content="Certified Digital Marketing company,Certified Digital Marketing company in gurgaon,Certified Digital Marketing company in India ,Certified Digital Marketing company near me ,Digital Marketing Service in Gurgaon ,Digital Marketing Service in India "
        />
      </Helmet>
      {/* certification section starts from here */}
      <div className={Styles.about_page_section}>
        <div className={Styles.main_wrapper}>
          <div className={Styles.about_page_container}>
            <div className={Styles.about_page_content}>
              <div className={Styles.cont1}>
                <h1>Certifications</h1>
                <p>We live and breathe digital. The Internet is our home.</p>
                <div className={Styles.btns}>
                  <NavLink className={Styles.link} to="/portfolio">
           
                    View Portfolio
                  </NavLink>
                </div>
              </div>

              <div className={Styles.cont2}>
                <img
                  src={skywalk}
                  alt="skywalk best digital marketing team in Gurgaon"
                />
              </div>
            </div>
            <div className={Styles.image}>
              <img
                src={skywalkoffice}
                alt="best business analysis services"
              />
              <img
                src={skywalkoffice3}
                alt="the best digital marketing competitor in Gurgaon"
              />
            </div>
          </div>
        </div>
        <div className={Styles.vacant}>
          <div className={Styles.temp_image}>
            <img loading="lazy" src={skywalkoffice} alt="best business analysis services" />
            <img
              src={skywalkoffice3}
              alt="the best digital marketing competitor in Gurgaon"
            />
          </div>
        </div>
      </div>
      {/* certification section ends here */}

      {/* our office section */}
      <section className={Styles.our_office_section}>
        <div className={Styles.our_office}>
          <div className={Styles.our_office_heading}>
            <h3>Our Office</h3>
            <h2>Visit Our Work Places</h2>
          </div>
          <div className={Styles.office}>
            <figure className={Styles.snip0015}>
              <img
                src={officeImage1}
                alt="digital marketing agency in Gurgaon "
              />
              <figcaption>
                <h2>Gurgaon, India</h2>
                <p>Tower B-4, Unit No. 1101-02, 11th Floor, Spaze I Tech Park, Sector-49, Gurgaon, Haryana-122018</p>
              </figcaption>
            </figure>

            <figure className={Styles.snip0015}>
              <img
                src={officeImage2}
                alt="best digital services in UP in India"
              />
              <figcaption>
                <h2>Up, India</h2>
                <p>Sec-63, Noida Uttar Pradesh India</p>
              </figcaption>
            </figure>

            <figure className={Styles.snip0015}>
              <img
                src={officeImage3}
                alt="the best digital marketing services in PHILIPPINES"
              />
              <figcaption>
                <h2>Philippines</h2>
                <p>Philippines</p>
              </figcaption>
            </figure>
            <figure className={Styles.snip0015}>
              <img
                src={officeImage5}
                alt="digital marketing agency coming soon"
              />
              <figcaption>
                <h2>Coming Soon</h2>
                <p>starting soon</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Certifications;
