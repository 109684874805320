import React, { Fragment } from "react";
import Styles from "./web_design.module.css";
import { Helmet } from "react-helmet";
import Industries_We_Work from "../../components/Industries We Work With/IndustriesWeWork";

// image imports
import web_design from "../../img/web-design.png";
import customWebDesign from "../../img/custom-web-designing.png";
import responsiveWebDesign from "../../img/responsive-web-designing.png";
import dynamicWebsiteDesign from "../../img/dynamic-website-design.png";

import contraction from "../../img/contraction.png";

import { RiNodeTree } from "react-icons/ri";
import { BsBezier, BsTruck } from "react-icons/bs";
import { BiLayer } from "react-icons/bi";

const WebDesign = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          Website Designing Company in Gurgaon | Skywalk Technologies
        </title>

        <link rel="canonical" href="https://www.skywalktech.in/web-design" />

        <meta
          name="description"
          content="Skywalk Technologies best website designing and development services Agency in Gurgaon, we offer  static and dynamic website design services for your business"
        />

        <meta
          name="keywords"
          content="Web Design Company Gurgaon , Website Designing in India , Being considered the best digital marketing agency in Gurgaon, We provide SEO services, PPC, social media, web design, Online marketing, brand promotions								
          We are a Website Designing Company in Gurgaon , as a website development company we offer dynamic and responsive website designing and development services								"
        />
      </Helmet>
      <section className={Styles.page_header_container}>
        <div className={Styles.page_header}>
          <div className={Styles.main_heading}>
            <h1>
              Dynamic and Responsive Web Design Service Company In Gurgaon,
              India
            </h1>
            <p className={Styles.lead}>
              Skywalk Technologies is the Gurgaon based firm that takes the
              responsibility of various businesses to make them go digital. Not
              only this, we have expertise in Creating Dynamic websites that
              result in smooth running. We make websites with trending ideas and
              all of them are Responsive. Connect with us and get another level
              of experience with our web design Services.
            </p>
          </div>
          {/* <div className={Styles.plane_div}></div> */}
        </div>
      </section>

      {/* feature section  */}
      <section className={Styles.feature_section}>
        <div className={Styles.feature_wrapper}>
          <div className={Styles.feature_heading}>
            <h2>Why Does Your Business Need A Website Design ?</h2>
          </div>
          <div className={Styles.feature_content}>
            <div className={Styles.left}>
              <img
                src={web_design}
                alt="the best web development and web design company in Gurgaon"
              />
            </div>
            <div className={Styles.right}>
              <p>
                The digital availability for all businesses impacts the success
                of the company regardless of the brand that business is
                promoting. A website is required as without it the clients may
                doubt about the validity of the organization. An online website
                works as an opportunity to create an impression on the people
                interested in the product of your niche.
                <br />A website gives site visitors a sense of comfortness and
                assurance that this business with a site is a reliable one. This
                digital presence of business via a website is a great way to
                generate more revenue and accessibility to the broad range of
                interested clientele.
              </p>

              <strong className={Styles.our_services}>
                We cater to services like:
              </strong>
              <ul>
                <li>Custom Web Design</li>
                <li>UX/UI Design</li>
                <li>Mobile App Design</li>
                <li>e-Commerce Website Design</li>
                <li>Wordpress Website Design</li>
                <li>Website Speed Optimization</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Industries we work with section */}
      <Industries_We_Work />

      {/*Second feature section starts */}
      <section className={Styles.our_features}>
        <div className={Styles.our_feature_wrapper}>
          <div className={Styles.our_feature_heading}>
            <h3>SkyWalk Technology Offers Customizable Responsive Website</h3>
            <p>
              Skywalk Technologies offers the Customizable Responsive website in
              order to ensure you to make changes in the site made by us as per
              your requirements and needs. We understand that always suggest
              that you should invest in only a customizable and responsive
              website if you want to stay ahead of other businesses. We have an
              experienced team of coders who have the specialization in creating
              a customizable and responsive website. This strategy we apply, as
              most of the businesses today add pre-made templates, designs and
              other features, which is easy but very common.
            </p>
          </div>
          <div className={Styles.our_feature_cards}>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img
                  src={responsiveWebDesign}
                  alt="best digital responsive web design services"
                />
              </div>
              <h3>Responsive web Designing</h3>
              <p>
                The use of Smart-Technology became a part of the regular
                lifestyle, we create sites that support different devices.
              </p>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img
                  src={customWebDesign}
                  alt="we provide custom web designing services"
                />
              </div>
              <h3>Custom Web Designing</h3>
              <p>
                The Custom Web designing of site for any product, business or
                service, enables the user to have a unique site.
              </p>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-delay="100"
              className={Styles.card}
            >
              <div className={Styles.img}>
                <img
                  src={dynamicWebsiteDesign}
                  alt="best dynamic website design near me "
                />
              </div>
              <h3>Dynamic Website Design</h3>
              <p>
                We have expertise in dynamic website designs where the changes
                can be made as per the need of the client.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* work process section starts here*/}
      <section className={Styles.work_process_section}>
        <div className={Styles.work_process_container}>
          <div className={Styles.content_wrapper}>
            <div className={Styles.heading}>
              <h3>Process</h3>
              <h2>
                Why Choose Skywalk technologies For Your Innovative Services
              </h2>
              <p>
                Our firm uses the trending technologies and offers solutions to
                all the digital marketing problems here, at one platform.
              </p>
            </div>
            <div className={Styles.content_section}>
              <div className={Styles.images_section}>
                <img
                  src={contraction}
                  alt="the best digital marketing innovative services"
                />
              </div>
              <div className={Styles.text_content_cont}>
                <div className={Styles.tree_structure}>
                  <div className={Styles.timeline_icon}>
                    <RiNodeTree className={Styles.ic} />
                  </div>
                  <div className={Styles.line}></div>
                  <div className={Styles.timeline_icon}>
                    <BsBezier className={Styles.ic} />
                  </div>
                  <div className={Styles.line}></div>
                  <div className={Styles.timeline_icon}>
                    <BiLayer className={Styles.ic} />
                  </div>
                  <div className={Styles.line}></div>
                  <div className={Styles.timeline_icon}>
                    <BsTruck className={Styles.ic} />
                  </div>
                </div>
                <div className={Styles.text_cont}>
                  <div>
                    <h2>Customized Options</h2>
                    <p>
                      We make the client familiar with all the options available
                      that can be used to customize the site as per their need.
                    </p>
                  </div>
                  <div>
                    <h2> Dynamic Marketing Strategies</h2>
                    <p>
                      We provide you with the most outstanding marketing
                      strategies that are adaptable together with your company.
                    </p>
                  </div>
                  <div>
                    <h2>Powerful Code</h2>
                    <p>
                      Our codes are delicate but they are strong enough to break
                      the traditional thinking about these delicate codes.
                    </p>
                  </div>
                  <div>
                    <h2>Bug-Free Applications</h2>
                    <p>
                      Once any app is made successfully by us, we are
                      responsible to update it and look upon its challenges in
                      the market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default WebDesign;
