 const schema = {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Homepage",
      "item": "https://www.skywalktech.in/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Company Profile",
      "item": "https://www.skywalktech.in/companyprofile"  
    },{
      "@type": "ListItem", 
      "position": 3, 
      "name": "Certifications",
      "item": "https://www.skywalktech.in/certifications"  
    },{
      "@type": "ListItem", 
      "position": 4, 
      "name": "Web Design",
      "item": "https://www.skywalktech.in/web-design"  
    },{
      "@type": "ListItem", 
      "position": 5, 
      "name": "Web Development",
      "item": "https://www.skywalktech.in/web-development"  
    },{
      "@type": "ListItem", 
      "position": 6, 
      "name": "Anugular Js",
      "item": "https://www.skywalktech.in/angularjs"  
    },{
      "@type": "ListItem", 
      "position": 7, 
      "name": "Java Development",
      "item": "https://www.skywalktech.in/java-development"  
    },{
      "@type": "ListItem", 
      "position": 8, 
      "name": "Php Development",
      "item": "https://www.skywalktech.in/php-development"  
    },{
      "@type": "ListItem", 
      "position": 9, 
      "name": "Ecommerce Website Development",
      "item": "https://www.skywalktech.in/ecommerce-website-development"  
    },{
      "@type": "ListItem", 
      "position": 10, 
      "name": "Iphone App Development",
      "item": "https://www.skywalktech.in/iphone-app-development"  
    },{
      "@type": "ListItem", 
      "position": 11, 
      "name": "Android App Development",
      "item": "https://www.skywalktech.in/android-app-development"  
    },{
      "@type": "ListItem", 
      "position": 12, 
      "name": "Flutter App Development",
      "item": "https://www.skywalktech.in/flutter-app-development"  
    },{
      "@type": "ListItem", 
      "position": 13, 
      "name": "Blockchain App Development",
      "item": "https://www.skywalktech.in/blockchain-app-development"  
    },{
      "@type": "ListItem", 
      "position": 14, 
      "name": "Mobile Game App Development",
      "item": "https://www.skywalktech.in/mobile-game-app-development"  
    },{
      "@type": "ListItem", 
      "position": 15, 
      "name": "Wearable App Development",
      "item": "https://www.skywalktech.in/wearable-app-development"  
    },{
      "@type": "ListItem", 
      "position": 16, 
      "name": "Digital Marketing",
      "item": "https://www.skywalktech.in/digital-marketing"  
    },{
      "@type": "ListItem", 
      "position": 17, 
      "name": "Search Engine Optimization",
      "item": "https://www.skywalktech.in/search-engine-optimization"  
    },{
      "@type": "ListItem", 
      "position": 18, 
      "name": "Social Media Marketing",
      "item": "https://www.skywalktech.in/social-media-marketing"  
    },{
      "@type": "ListItem", 
      "position": 19, 
      "name": "Content Marketing",
      "item": "https://www.skywalktech.in/content-marketing"  
    },{
      "@type": "ListItem", 
      "position": 20, 
      "name": "Pay Per Click",
      "item": "https://www.skywalktech.in/pay-per-click"  
    },{
      "@type": "ListItem", 
      "position": 21, 
      "name": "Portfolio",
      "item": "https://www.skywalktech.in/portfolio"  
    },{
      "@type": "ListItem", 
      "position": 22, 
      "name": "Case Study",
      "item": "https://www.skywalktech.in/casestudy"  
    },{
      "@type": "ListItem", 
      "position": 23, 
      "name": "Contact us",
      "item": "https://www.skywalktech.in/contact-us"  
    },{
      "@type": "ListItem", 
      "position": 24, 
      "name": "Career",
      "item": "https://www.skywalktech.in/career"  
    }]
  }



  export default schema;