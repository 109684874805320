import React,{Fragment} from 'react'
import {NavLink} from "react-router-dom" 
import Styles from "./marketing.module.css"
const Marketing = () => {
return (
<Fragment>
    <div className={Styles.marketing_container}>
        <div className={Styles.marketing_section}>
            <div className={Styles.heading_cont}>
                <h4>Our Key Excellence in Digital Marketing Services</h4>
                <p>Our clients' digital business expansion has always been our top goal. We are delighted to elevate your brand to that level, so it may reach its intended strength as more consumers rely on digital information about any brand. We give you unlimited services in digital marketing, website development, mobile app development, gaming app development, software development, and UI/UX design. Furthermore, we provide an effortless approach to our services and timely deliver our work on time, which leads to upgraded productivity.
</p>
            </div>
            <div className={Styles.marketing_item_cont}>
                <div className={Styles.marketing_item}>
                    <h4>Trusted Insights</h4>
                    <p>Forward-thinking insights, verified right way research, robust metrics and data to help you.</p>
                </div>
                <div className={Styles.marketing_item}>
                    <h4>Strategic Advice</h4>
                    <p>Well guidance to help you focus on the issues and changes that truly matter.</p>
                </div>
                <div className={Styles.marketing_item}>
                    <h4>Practical tools</h4>
                    <p>Tools help your strategy changes into decisions and execute for measurable results.</p>
                </div>
                
               

            </div>
        </div>
    </div>
</Fragment>
)
}

export default Marketing