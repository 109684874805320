import React, { Fragment, useRef } from "react";
import Styles from "./scroll.module.css";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
const ScrollToTopBtn = () => {
  const btn = useRef(null);
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  window.onscroll = () => {
    if (
      document.body.scrollTop >= 400 ||
      document.documentElement.scrollTop >= 400
    ) {
      btn.current.style.display = "block";
    } else {
      btn.current.style.display = "none";
    }
  };

  return (
    <Fragment>
      <div className={Styles.cont}>
        <button
          ref={btn}
          className={Styles.scrollBtn}
          onClick={() => scrollToTop()}
        >
          <BsFillArrowUpCircleFill className={Styles.icon} />
        </button>
      </div>
    </Fragment>
  );
};

export default ScrollToTopBtn;
