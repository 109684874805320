import React,{Fragment} from "react";
import { Helmet } from "react-helmet";
// import logo from "../../img/skywalktechnologies.png"
import logo from "../../img/404.jpeg"
import "./loader.css"
const Loader = ()=>{
    return (
        <Fragment>
        <Helmet>
            <title>
               404 - page not found 
            </title>
        </Helmet>

            <div className="wrapper">
                <div className="animation-cont">
                    <img loading="lazy" src={logo} alt="page not exists" />
                    <div className="dash">
                        <div className="move"></div>
                    </div>
                </div>


            </div>
        </Fragment>
    )
}

export default Loader;